import type { ReactElement } from 'react';
import React from 'react';
import type { PageProps } from 'gatsby';
import { graphql } from 'gatsby';
import type { IGatsbyImageData, ImageDataLike } from 'gatsby-plugin-image';
import { getImage } from 'gatsby-plugin-image';
import ClientSideRenderer from '@clearscore/shared.website-client-side-renderer';

import Layout from '../components/layout';

const LoggedOutOffersResultsTemplate = (props: PageProps<Queries.LoggedOutOffersQuery>): ReactElement => {
    const { location, data } = props;

    return (
        <Layout
            meta={{
                title: 'ClearScore | See your results - Balance Transfer Credit Cards | ClearScore GB',
                description:
                    'Find out which credit cards you could get accepted for, without harming your credit score',
                robots: 'noindex, nofollow',
            }}
            location={location.pathname}
        >
            {({ commonModules }): ReactElement => (
                <ClientSideRenderer lazy={() => import('../components/verticals/logged-out-offers-results')}>
                    {(LoggedOutOffersResults) => (
                        <LoggedOutOffersResults
                            backgroundImage={getImage(data.backgroundImage as ImageDataLike) as IGatsbyImageData}
                            appStoreConfig={commonModules.app_store}
                            cookiePolicyConfig={commonModules.cookie_policy}
                        />
                    )}
                </ClientSideRenderer>
            )}
        </Layout>
    );
};

export const query = graphql`
    query LoggedOutOffers {
        backgroundImage: file(absolutePath: { regex: "/gb/logged-out-offers/leaves-background.png/" }) {
            childImageSharp {
                gatsbyImageData(
                    quality: 100
                    breakpoints: [375, 768, 1024, 1200, 1440]
                    layout: FULL_WIDTH
                    formats: [AUTO, WEBP, AVIF]
                )
            }
        }
    }
`;

export default LoggedOutOffersResultsTemplate;
