import React from 'react';

type ReactLazyFunction = Parameters<typeof React.lazy>['0'];
type ReactLazyAwaited<T extends ReactLazyFunction> = Awaited<ReturnType<T>>['default'];

export interface IClientSideRendererProps<T extends ReactLazyFunction> {
    lazy: T;
    fallback?: React.SuspenseProps['fallback'];
    children: (s: ReactLazyAwaited<T>) => React.ReactElement;
}

const ClientSideRenderer = <T extends ReactLazyFunction>({
    lazy,
    fallback = <React.Fragment />,
    children,
}: IClientSideRendererProps<T>): React.ReactElement => {
    const isClient = typeof window !== 'undefined' && typeof document !== 'undefined';
    const ChildComponent = React.lazy(lazy);

    if (!isClient) return <React.Fragment />;

    return <React.Suspense fallback={fallback}>{children(ChildComponent)}</React.Suspense>;
};

export default ClientSideRenderer;
